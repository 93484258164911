import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openViewDialog: false,
  orderId: null,
  start_date: null,
  end_date: null
};

const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    openSkuViewDialog: (state, action) => {
      const { orderId, start_date, end_date } = action.payload;
      state.openViewDialog = true;
      state.orderId = orderId;
      state.start_date = start_date;
      state.end_date = end_date;
    },
    closeSkuViewDialog: (state) => {
      state.openViewDialog = false;
      state.orderId = null;
      state.start_date = null;
      state.end_date = null;
    },
  },
});

export const { openSkuViewDialog, closeSkuViewDialog } = dialogSlice.actions;
export default dialogSlice.reducer;
