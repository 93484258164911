import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AppBarWithDrawer from "./components/AppBarWithDrawer";
import Login from "./pages/Auth/Login";
import AppRoutes from "./routes";

function App() {
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    const checkTokenExpiration = () => {
      const tokenExpiration = localStorage.getItem("tokenExpiration");
      const currentTime = new Date().getTime();

      if (tokenExpiration && currentTime > tokenExpiration) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        setToken(null);
      } else {
        setToken(localStorage.getItem("token"));
      }
    };

    // Check token expiration on initial load
    checkTokenExpiration();

    // Remove the interval-based reload
    // const intervalId = setInterval(() => {
    //   checkTokenExpiration();
    //   window.location.reload();
    // }, 5 * 60 * 1000); // 5 minutes

    // return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login setToken={setToken} />} />
          {token ? (
            <Route path="/" element={<AppBarWithDrawer />}>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="*" element={<AppRoutes />} />
            </Route>
          ) : (
            <Route path="*" element={<Navigate to="/login" />} />
          )}
        </Routes>
      </Router>
    </>
  );
}

export default App;
