import { configureStore } from "@reduxjs/toolkit";
import { allOrderDateFilterReducer, categoriesDateFilterReducer } from "../feature/dateFilter/dateFilterSlice.js";
import dialogReducer from "../feature/dialog/dialogSlice.js"

export const store = configureStore({
  reducer: {
    allOrderDateFilter: allOrderDateFilterReducer, // Rename to match usage
    categoriesDateFilter: categoriesDateFilterReducer, // Rename to match usage
    dialog: dialogReducer,
  },
});
