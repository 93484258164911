import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getRequestInstanceTwo } from "../../apiServices/apiCall";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  TextField,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CustomLoader } from "../../components/CustomLoader/CustomLoader";
import SearchIcon from "@mui/icons-material/Search";

const StyledGrid = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  backgroundColor: "#f6f5fa",
  fontFamily: "Arial, sans-serif",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0),
  fontSize: "2rem",
  fontWeight: "bold",
  textAlign: "center",
  color: "#5052ba",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  margin: "auto",
  padding: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    margin: theme.spacing(2),
  },
  backgroundColor: "#fff",
  borderRadius: "15px",
  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2)",
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "auto", // Auto height to fit content
  minHeight: 250, // Set a minimum height to ensure consistency
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: "190px", // Set a fixed height for all images
  width: "100%", // Ensure the image takes up the full width of the card
  objectFit: "inherit", // Ensure the image covers the entire area
  borderRadius: "12px",
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: `${theme.spacing(1)} ${theme.spacing(1)} 0px ${theme.spacing(1)}`,
  textAlign: "center",
  height: "auto", // Let content dictate its height
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Arial, sans-serif",
  textAlign: "center",
  color: "darkcyan",
}));

const OfferIdTypography = styled(Typography)(({ theme }) => ({
  color: "saddlebrown",
  fontWeight: "bold",
}));

const SaleOffer = () => {
  const [saleOffers, setSaleOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentOfferIds, setCurrentOfferIds] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getRequestInstanceTwo("/api/all_saleoffers");
      const offers = response.result;

      // Extract active offer IDs where is_active === 1
      const activeOffers = offers.filter(
        (offer) => offer.is_active === 1 || offer.offer_id === "SPD1"
      );
      const activeOfferIds = activeOffers.map((offer) => offer.offer_id);

      setSaleOffers(offers);
      setFilteredOffers(offers); // Set initial filtered offers
      setCurrentOfferIds(activeOfferIds); // Store active offer IDs
    } catch (error) {
      console.error("Error fetching sale offers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filtered = saleOffers.filter(
      (offer) =>
        offer.offer_name
          .toLowerCase()
          .includes(searchQuery.trim().toLowerCase()) ||
        offer.offer_id.toString().toLowerCase().includes(searchQuery)
    );
    setFilteredOffers(filtered);
  }, [searchQuery, saleOffers]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter current and expired offers dynamically
  const currentOffers = filteredOffers.filter((offer) =>
    currentOfferIds.includes(offer.offer_id)
  );

  // Filter expired offers
  const expiredOffers = filteredOffers.filter(
    (offer) => !currentOfferIds.includes(offer.offer_id)
  );

  // Sort the expired offers by created_at in descending order
  expiredOffers.sort((a, b) => b.created_at - a.created_at);

  const renderCards = (offers) =>
    offers.map((offer) => (
      <Grid item xs={12} sm={6} md={4} lg={2.4} key={offer.id}>
        <StyledCard>
          <Link
            to={
              localStorage.getItem("user") === "admin"
                ? `/sales-offer/${offer.offer_name.replace(/ /g, "")}/${
                    offer.offer_id
                  }?time-frame=Monthly`
                : `/sales-offer/${offer.offer_name.replace(/ /g, "")}/${
                    offer.offer_id
                  }/${"None"}/${localStorage.getItem(
                    "user"
                  )}?time-frame=Monthly`
            }
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <StyledCardMedia
              component="img"
              src={`http://142.93.208.119/media/${offer.image}`}
              alt={offer.offer_name}
              onError={(e) => {
                e.target.src = "https://via.placeholder.com/345x200";
              }}
            />
            <StyledCardContent sx={{ background: "aliceblue" }}>
              {offer.offer_name.length > 15 ? (
                <Tooltip
                  title={offer.offer_name || "-"}
                  placement="right-start"
                  arrow
                >
                  <StyledTypography variant="h6" component="div">
                    {`${offer.offer_name.slice(0, 15)}...`}
                  </StyledTypography>
                </Tooltip>
              ) : (
                <StyledTypography variant="h6" component="div">
                  {offer.offer_name}
                </StyledTypography>
              )}

              <OfferIdTypography variant="body2">
                Offer ID: {offer.offer_id}
              </OfferIdTypography>
            </StyledCardContent>
          </Link>
        </StyledCard>
      </Grid>
    ));

  return (
    <StyledGrid container spacing={2}>
      <CustomLoader open={isLoading} />

      {/* Container for Search Bar and Active Offers */}
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ background: "linear-gradient(45deg, #343a83, transparent)" }}
      >
        {/* Current Offers (Left) */}
        <Grid item xs={12} sm="auto" ml={3}>
          <SectionTitle sx={{ color: "lavender", fontSize: "1.3rem" }}>
            Active Offers
          </SectionTitle>
        </Grid>

        {/* Search Bar (Right) */}
        <Grid item xs={12} sm="auto">
          <TextField
            variant="outlined"
            placeholder="Search by Offer Id..."
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "#6b6b6b" }} />
                </InputAdornment>
              ),
              style: {
                height: "39px", // Exact height
                padding: "0 12px", // Adjust padding for inner spacing
              },
            }}
            sx={{
              width: "219px", // Exact width
              height: "39px", // Exact height
              borderRadius: "8px", // Rounded corners
              backgroundColor: "#f5f5f5", // Light background
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
              "& .MuiInputBase-input": {
                "&::placeholder": {
                  color: "green", // Green placeholder color
                  opacity: 1, // Ensure full visibility if necessary
                },
              },
            }}
          />
        </Grid>
      </Grid>

      {/* Active Offers Cards */}
      {/* Active Offers Cards */}
      <Grid container spacing={4}>
        {currentOffers && currentOffers.length > 0 ? (
          renderCards(currentOffers)
        ) : (
          <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
            <Typography variant="body1" color="textSecondary">
              No data is available
            </Typography>
          </Grid>
        )}
      </Grid>

      {/* Expired Offers */}
      <Grid item xs={12}>
        <SectionTitle
          sx={{
            color: "lavender",
            textAlign: "left",
            background: "linear-gradient(45deg, #343a83, transparent)",
            fontSize: "1.3rem",
          }}
          pl={2}
        >
          Expired Offers
        </SectionTitle>
      </Grid>
      {/* Expired Offers Cards */}
      <Grid container spacing={4}>
        {expiredOffers && expiredOffers.length > 0 ? (
          renderCards(expiredOffers)
        ) : (
          <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
            <Typography variant="body1" color="textSecondary">
              No data is available
            </Typography>
          </Grid>
        )}
      </Grid>
    </StyledGrid>
  );
};

export default SaleOffer;
